@import url("https://cdn.syncfusion.com/ej2/material.css");

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media screen and (max-width: 800px) {
  .sidebar {
    z-index: 10000000;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  /* background-color: rgb(202, 198, 198); */
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
